<template>
  <div class="download positionr pt30 pb90">
    <div class="w1100">
      <!-- 收银系统 -->
      <div class="cashierDown bg-white bbox animated fadeInDownBig">
        <div class="flex align-center">
          <img src="../../assets/images/download/dianjiaoshou.png" alt="" style="width
          60px;height:60px"/>
          <div class="ml15">
            <p class="f22 fw700">店教授系统</p>
            <p class="mt3">店教授收银，生意装进口袋里</p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="cashierItem mt20">
            <div class="flex align-center justify-between ItemUp pb20">
              <div>
                <p class="f16 fw700">店教授收银 Windows客户端</p>
                <p class="tip tipy f12">Windows</p>
              </div>
              <img src="../../assets/images/download/pc.png" alt="" />
            </div>
            <div class="mt20 mb20 text999" style="height: 72px">
              <p>
                覆盖零售、餐饮、茶饮、服装、母婴、烘焙、生鲜、艺培、美业、美妆、宠物、批发、商圈、酒吧等行业。
              </p>
              <p>适用于POS一体机、PC触摸屏、家用电脑、笔记本等。</p>
            </div>
            <a href="https://pos.pluss.cn/resources/sdk/云蓝收银3.0.4.msi">
              <div class="downloadBtn textc pointer transition flex align-center justify-center">
                <img src="../../assets/images/download/down.png" alt="" />
                <span class="ml5">点击下载</span>
              </div>
            </a>
          </div>
          <!-- <div class="cashierItem mt20">
            <div class="flex align-center justify-between ItemUp pb20">
              <div>
                <p class="f16 fw700">店教授管家APP</p>
                <p class="tip tipg f12">安卓</p>
              </div>
              <img src="../../assets/images/download/手机收银(1).png" alt="" style="width:40px;height:60px"/>
            </div>
            <div class="mt20 mb20 text999" style="height: 72px">
              <p>
                覆盖零售、餐饮、茶饮、服装、母婴、烘焙、生鲜、艺培、美业、美妆、宠物、批发、商圈、酒吧等行业。
              </p>
              <p>适用于POS一体机、PC触摸屏、平板等。</p>
            </div>
            <div class="downloadBtn textc pointer transition flex align-center justify-center" @mousemove="opemImg" @mouseout="closeImg">
              <img src="../../assets/images/download/down.png" alt="" />
              <span class="ml5">点击下载</span>
              <img src="../../assets/images/download/android.png" alt="" srcset="" class="code" v-if="isShow">
            </div>
          </div> -->
          <div class="cashierItem mt20">
            <div class="flex align-center justify-between ItemUp pb20">
              <div>
                <p class="f16 fw700">店教授收银 Android客户端</p>
                <p class="tip tipg f12">安卓</p>
              </div>
              <img src="../../assets/images/download/az.png" alt="" />
            </div>
            <div class="mt20 mb20 text999" style="height: 72px">
              <p>
                覆盖零售、餐饮、茶饮、服装、母婴、烘焙、生鲜、艺培、美业、美妆、宠物、批发、商圈、酒吧等行业。
              </p>
              <p>适用于POS一体机、PC触摸屏、家用电脑、笔记本等。</p>
            </div>
            <a href="https://pos.pluss.cn/resources/sdk/shouyin_android.apk">
              <div class="downloadBtn textc pointer transition flex align-center justify-center">
                <img src="../../assets/images/download/down.png" alt="" />
                <span class="ml5">点击下载</span>
              </div>
            </a>
          </div>

          
          <div class="cashierItem mt20">
            <div class="flex align-center justify-between ItemUp pb20">
              <div>
                <p class="f16 fw700">店教授收银 移动收银台</p>
                <p class="tip tipg f12">安卓</p>
              </div>
              <img src="../../assets/images/download/mpos.png" alt="" />
            </div>
            <div class="mt20 mb20 text999" style="height: 72px">
              <p>
                覆盖零售、餐饮、茶饮、服装、母婴、烘焙、生鲜、艺培、美业、美妆、宠物、批发、商圈、酒吧等行业。
              </p>
              <p>适用于POS一体机、PC触摸屏、家用电脑、笔记本等。</p>
            </div>
            <a href="https://pos.pluss.cn/resources/sdk/mpos.apk">
              <div class="downloadBtn textc pointer transition flex align-center justify-center">
                <img src="../../assets/images/download/down.png" alt="" />
                <span class="ml5">点击下载</span>
              </div>
            </a>
          </div>
          <div class="cashierItem mt20">
            <div class="flex align-center justify-between ItemUp pb20">
              <div>
                <p class="f16 fw700">店教授收银 管家APP</p>
                <p class="tip tipg f12">安卓</p>
                <p class="tip tipb f12 ml10">IOS</p>
              </div>
              <img src="../../assets/images/download/手机收银(1).png" alt="" style="width:40px;height:60px"/>
            </div>
            <div class="mt20 mb20 text999" style="height: 72px">
              <p>
                覆盖零售、餐饮、茶饮、服装、母婴、烘焙、生鲜、艺培、美业、美妆、宠物、批发、商圈、酒吧等行业。
              </p>
              <p>适用于POS一体机、PC触摸屏、平板等。</p>
            </div>
            <div class="downloadBtn textc pointer transition flex align-center justify-center" @mousemove="opemImg" @mouseout="closeImg">
              <img src="../../assets/images/download/down.png" alt="" />
              <span class="ml5">点击下载</span>
              <img src="../../assets/images/download/android.png" alt="" srcset="" class="code" v-if="isShow">
            </div>
          </div>

          <div class="cashierItem mt20">
            <div class="flex align-center justify-between ItemUp pb20">
              <div>
                <p class="f16 fw700">店教授收银 iPad点餐系统</p>
                <!-- <p class="tip tipg f12">安卓</p> -->
                <p class="tip tipb f12 ml10">IOS</p>
              </div>
              <img src="../../assets/images/download/平板收银.png" alt="" style="width:100px"/>
            </div>
            <div class="mt20 mb20 text999" style="height: 72px">
              <p>适用于安卓5.0版本及以上各类安卓平板及安卓一体机。</p>
              <p>适用于iOS8.0版本以上iPhone手机，iOS9.0以上需进行设置。</p>
            </div>
            <div class="downloadBtn textc pointer transition flex align-center justify-center" @mouseover="opemImg1" @mouseout="closeImg1">
              <img src="../../assets/images/download/down.png" alt="" />
              <span class="ml5">点击下载</span>
              <img src="../../assets/images/download/ipad1.png" alt="" srcset="" class="code" v-if="isShow1">
            </div>
          </div>
          <div class="cashierItem mt20">
            <div class="flex align-center justify-between ItemUp pb20">
              <div>
                <p class="f16 fw700">店教授收银 Android Pad点餐系统</p>
                <p class="tip tipg f12">安卓</p>
              </div>
              <img src="../../assets/images/download/平板收银.png" alt="" style="width:100px"/>
            </div>
            <div class="mt20 mb20 text999" style="height: 72px">
              <p>
                覆盖零售、餐饮、茶饮、服装、母婴、烘焙、生鲜、艺培、美业、美妆、宠物、批发、商圈、酒吧等行业。
              </p>
              <p>适用于POS一体机、PC触摸屏、平板等。</p>
            </div>
            <div class="downloadBtn textc pointer transition flex align-center justify-center" @mouseover="opemImg2" @mouseout="closeImg2">
              <img src="../../assets/images/download/down.png" alt="" />
              <span class="ml5">点击下载</span>
              <img src="../../assets/images/download/androidPad.png" alt="" srcset="" class="code" v-if="isShow2">
            </div>
          </div>
          <div class="cashierItem mt20">
            <div class="flex align-center justify-between ItemUp pb20">
              <div>
                <p class="f16 fw700">小程序商城</p>
                <p class="tip tiph f12">微信小程序</p>
              </div>
              <img src="../../assets/images/download/小程序点单.png" alt="" style="width:40px;height:60px"/>
            </div>
            <div class="flex justify-between">
              <div class="mt20 mb20 text999" style="height: 72px;width:60%">
                <p>
                  覆盖零售、餐饮、茶饮、服装、母婴、烘焙、生鲜、艺培、美业、美妆、宠物、批发、商圈、酒吧等行业。
                </p>
              </div>
              <div style="margin-left:20px;margin-top:20px;width:40%">
                <img src="../../assets/images/download/saomaCode.jpg" alt=""/>
              </div>
            </div>
          </div>
          <div class="cashierItem mt20">
            <div class="flex align-center justify-between ItemUp pb20">
              <div>
                <p class="f16 fw700">扫码下单</p>
                <p class="tip f12">
                  一键下单，快速完成商品选购，方便顾客，提高效率。
                </p>
              </div>
              <img src="../../assets/images/download/扫码点单.png" alt="" style="width:40px;height:60px"/>
            </div>
            <div class="flex justify-between">
              <div class="mt20 mb20 text999" style="height: 72px;width:60%">
                <p>
                  使用流程：用户扫码 - 选择商品 - 支付完成 - 订单通知(厨打) -
                  叫号取餐或服务员上菜
                </p>
              </div>
              <div style="margin-left:20px;margin-top:20px;width:40%">
                <img src="../../assets/images/download/saomaCode.jpg" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 更多软件 -->
      <div>
        <div class="flex align-center mt20 mb20">
          <span class="yuan"></span>
          <span class="f24 fw700 ml10">更多软件</span>
        </div>
        <div class="flex align-center justify-between">
          <div class="moreItem bg-white flex1">
            <div class="flex align-center justify-between ItemUp pb20">
              <div class="flex align-center">
                <img src="../../assets/images/download/logo_r.png" alt="" />
                <div class="ml15">
                  <p class="f16 fw700 mb5">店教授收银 管家APP</p>
                  <p class="tip tipg f12">安卓</p>
                  <p class="tip tipb f12 ml10">IOS</p>
                </div>
              </div>
              <div
                class="downloadBtn textc pointer transition flex align-center justify-center"
                style="padding: 5px 30px"
                @mouseover="opemImg3" @mouseout="closeImg3"
              >
                <img src="../../assets/images/download/down.png" alt="" />
                <span class="ml5">点击下载</span>
                <img src="../../assets/images/download/ipad1.png" alt="" srcset="" class="code" v-if="isShow3">
              </div>
            </div>
            <div class="mt20 mb20 text999">
              <p>
                实时掌握店铺信息，营业状况一目了然，经营报表丰富全面，全新的界面，全新体验。
              </p>
              <p>适用于：各类安卓手机、iPhone手机iOS8.0版本以上。</p>
            </div>
          </div>
          <div class="moreItem bg-white flex1 ml20">
            <div class="flex align-center justify-between ItemUp pb20">
              <div class="flex align-center">
                <img src="../../assets/images/download/logo_b.png" alt="" />
                <div class="ml15">
                  <p class="f16 fw700 mb5">店教授收银 服务商版</p>
                  <p class="tip tipg f12">安卓</p>
                  <p class="tip tipb f12 ml10">IOS</p>
                </div>
              </div>
              <div
                class="downloadBtn textc pointer transition flex align-center justify-center"
                style="padding: 5px 30px"
                @mouseover="opemImg4" @mouseout="closeImg4"
              >
                <img src="../../assets/images/download/down.png" alt="" />
                <span class="ml5">点击下载</span>
                <img src="../../assets/images/download/agent.png" alt="" srcset="" class="code" v-if="isShow4">
              </div>
            </div>
            <div class="mt20 mb20 text999">
              <p>
                商户可以通过app切换店铺，以及对店铺的会员、中餐桌台和商品进行管理，商家还可以随时随地查看数据报表和消费明细，是一款省心的商家收银助手。
              </p>
              <p>适用于：各类安卓手机、iPhone手机iOS8.0版本以上。</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 云蓝推荐安装组件软件 -->
      <div class="ctab_wrap wow fadeInUp" data-wow-delay="1000ms">
        <div class="mt50 mb10">
          <span style="font-size: 16px">·云蓝推荐浏览器&客户端安装组件</span>
        </div>
        <div class="ctab_item">
          <div
            class="clearfix pb20 pt20 flex textl bdbc bdrc"
            style="width: 50%"
          >
            <div class="dlblock ml200">
              <img
                src="../../assets/images/download/google.png"
                alt=""
                style="width: 100px"
              />
            </div>
            <div class="dlblock">
              <div class="mb10">谷歌浏览器</div>
              <a
                href="https://pos.pluss.cn/resources/sdk/ChromeSetup.exe"
                class="downloadButton"
                >下载</a
              >
            </div>
          </div>
          <div class="clearfix pb20 pt20 flex textl bdbc" style="width: 50%">
            <div class="dlblock ml200">
              <img
                src="../../assets/images/download/360.png"
                alt=""
                style="width: 100px"
              />
            </div>
            <div class="dlblock">
              <div class="mb10">360极速浏览器</div>
              <a
                href="https://pos.pluss.cn/resources/sdk/360jsSetup.exe"
                class="downloadButton"
                >下载</a
              >
            </div>
          </div>
          <div
            class="clearfix pb20 pt20 flex textl bdrc bdbc"
            style="width: 50%"
          >
            <div class="dlblock ml200">
              <img
                src="../../assets/images/download/net.png"
                alt=""
                style="width: 100px"
              />
            </div>
            <div class="dlblock">
              <div class="mb10">NET4.6下载</div>
              <a
                href="https://pos.pluss.cn/resources/sdk/NDP461-KB3102436-x86-x64-AllOS-ENU.exe"
                class="downloadButton"
                >下载</a
              >
            </div>
          </div>
          <div class="clearfix pb20 pt20 flex textl bdbc" style="width: 50%">
            <div class="dlblock ml200">
              <img
                src="../../assets/images/download/print.png"
                alt=""
                style="width: 100px"
              />
            </div>
            <div class="dlblock">
              <div class="mb10">芯烨58打印驱动下载</div>
              <a
                href="https://pos.pluss.cn/resources/sdk/XPrinter_Driver_58_76.rar"
                class="downloadButton"
                >下载</a
              >
            </div>
          </div>
          <div class="clearfix pb20 pt20 flex textl bdrc" style="width: 50%">
            <div class="dlblock ml200">
              <img
                src="../../assets/images/download/jiaqian.jpg"
                alt=""
                style="width: 100px"
              />
            </div>
            <div class="dlblock">
              <div class="mb10">价签驱动打印机</div>
              <a
                href="https://pos.pluss.cn/resources/sdk/佳博条码机驱动5.2.zip"
                class="downloadButton"
                >下载</a
              >
            </div>
          </div>
          <div class="clearfix pb20 pt20 flex textl" style="width: 50%">
            <div class="dlblock ml200">
              <img
                src="https://pos.pluss.cn/resources/sdk/sunkui.png"
                alt=""
                style="width: 100px"
              />
            </div>
            <div class="dlblock">
              <div class="mb10">向日葵远程连接工具</div>
              <a
                href="https://pos.pluss.cn/resources/sdk/SunloginClient_11.1.1.38222_x64.exe"
                class="downloadButton"
                >下载</a
              >
            </div>
          </div>
          <div class="clearfix pb20 pt20 flex textl bdrc" style="width: 50%">
            <div class="dlblock ml200">
              <img
                src="https://pos.pluss.cn/resources/sdk/logo.jpg"
                alt=""
                style="width: 100px"
              />
            </div>
            <div class="dlblock">
              <div class="mb10">网络打印机端口修改工具</div>
              <a
                href="https://pos.pluss.cn/resources/sdk/IP_V3.0C.rar"
                class="downloadButton"
                >下载</a
              >
            </div>
          </div>
          <div class="clearfix pb20 pt20 flex textl" style="width: 50%">
            <div class="dlblock ml200">
              <img
                src="https://pos.pluss.cn/resources/sdk/logo.jpg"
                alt=""
                style="width: 100px"
              />
            </div>
            <div class="dlblock">
              <div class="mb10">悦方客户端</div>
              <a
                href="https://pos.pluss.cn/resources/sdk/yuefang.msi"
                class="downloadButton"
                >下载</a
              >
            </div>
          </div>
          <div class="clearfix pb20 pt20 flex textl" style="width: 50%">
            <div class="dlblock ml200">
              <img
                src="https://pos.pluss.cn/resources/img/navicat.png"
                alt=""
                style="width: 100px"
              />
            </div>
            <div class="dlblock">
              <div class="mb10">Navicat</div>
              <a
                href="https://pos.pluss.cn/resources/sdk/navicat161_premium_cs_x64.exe"
                class="downloadButton"
                >下载</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
    }
  },
  methods:{
    opemImg(){
      this.isShow = true
    },
    closeImg(){
      this.isShow = false
    },
    opemImg1(){
      this.isShow1 = true
    },
    closeImg1(){
      this.isShow1 = false
    },
    opemImg2(){
      this.isShow2 = true
    },
    closeImg2(){
      this.isShow2 = false
    },
    opemImg3(){
      this.isShow3 = true
    },
    closeImg3(){
      this.isShow3 = false
    },
    opemImg4(){
      this.isShow4 = true
    },
    closeImg4(){
      this.isShow4 = false
    },
  }
};
</script>

<style scoped>
.download {
  min-height: 100vh;
  background: #fafafa url("../../assets/images/download/bg.png") no-repeat;
  background-size: contain;
}
.cashierDown {
  border-radius: 14px 14px 14px 14px;
  padding: 25px 20px;
}
.cashierItem {
  background: #f7f8fa;
  border-radius: 6px 6px 6px 6px;
  padding: 20px 30px;
  margin-right: 20px;
  width: calc((100% - 20px) / 2);
  box-sizing: border-box;
}
.cashierItem:nth-child(2n) {
  margin-right: 0;
}
.ItemUp {
  border-bottom: 1px solid #e4e4e4;
}
.tip {
  border-radius: 2px 2px 2px 2px;
  padding: 0 5px;
  display: inline-block;
  line-height: 18px;
}
.tipy {
  color: #ffb800;
  background-color: rgba(255, 184, 0, 0.1);
}
.tipg {
  color: #39a156;
  background-color: rgba(57, 161, 86, 0.1);
}
.tipb {
  color: #0084ff;
  background-color: rgba(0, 132, 255, 0.1);
}
.tipr {
  color: #fc4c51;
  background-color: rgba(253, 75, 84, 0.1);
}
.tiph {
  color: #666666;
  background-color: rgba(102, 102, 102, 0.1);
}
.downloadBtn {
  padding: 5px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0084ff;
  position: relative;
}
.downloadBtn .code{
  position: absolute;
  top: -140px;
  left: 310px;
}
.moreItem .downloadBtn .code{
  position: absolute;
  top: -140px;
  left: 5px;
}
.downloadBtn:hover {
  transform: translateY(-4px);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
}
.yuan {
  width: 12px;
  height: 12px;
  background: #0084ff;
  border-radius: 50%;
}
.moreItem {
  border-radius: 6px 6px 6px 6px;
  padding: 24px;
}
.ctab_item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff;
}
.clearfix {
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.downloadButton {
  border: 1px solid #00a6f4;
  color: #00a6f4;
  padding: 5px 20px;
  display: inline-block;
  margin-top: 5px;
  position: relative;
}
.bdrc{
  border-right: 1px solid #eee;
  box-sizing: border-box;
}
</style>